import React from "react";
import styled from "styled-components";

import ProjectActionButton from './ProjectAction.component';
import ProjectDetailsComponent from "./ProjectDetailsMobile.component";

const ProjectDateComponent = ({ date }) => {
    return <DateText>{date}</DateText>;
};

const DateText = styled.time`
  color: var(--grey, #848b9a);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin: 0;
`;

const ProjectCardMobile = ({title, description, date, buttonText, url}) => {
    return (
        <Card>
            <ProjectDateComponent date={date} />
            <Container>
                <ProjectTitle>{title}</ProjectTitle>
                <MobileContainer>
                    <ProjectDetailsComponent description={description} />
                    <Footer>
                        <ProjectActionButton text={buttonText} url={url} />
                    </Footer>
                </MobileContainer>
            </Container>
        </Card>
    );
};

const Card = styled.article`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    font-family: Inter, sans-serif;
    @media (max-width: 991px) {
        max-width: 100%;
    }

    @media (max-width: 1210px) {
        margin-bottom: 30px;
    }
`;

const ProjectTitle = styled.h2`
  color: var(--dark-blue, #000e2b);
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
    align-self: center;
    flex-grow: 1;
  letter-spacing: -1.2px;
    margin: 0;
    width: 400px;

    @media (max-width: 1210px) {
        justify-content: start;
        text-align: start;
        width: 100%;
        margin-top: 10px;
    }
`;

const MobileContainer = styled.div`
    display: flex;
    width: 100%;
    
    @media (max-width: 1210px) {
        display: flex;
        flex-direction: row;
    }

    @media (max-width: 649px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    
`

const Container = styled.div`
    align-self: stretch;
    display: flex;

    @media (max-width: 1210px) {
        display: flex;
        flex-direction: column;
    }
`;

const Footer = styled.footer`
    align-self: stretch;
    display: flex;
    margin-left: auto;

    @media (max-width: 1280px) {
        width: 350px;
    }

    @media (max-width: 649px) {
        margin-left: 0;
        width: 100%;
        align-self: start;
    }
`;

export default ProjectCardMobile;