import React, {forwardRef} from 'react';
import ProjectCard from './components/ProjectCard.component';
import styled from 'styled-components';
import JobBadge from '../../ui/Objects/Jobs.object.component';
import ProjectCardMobile from './components/ProjectCard.mobile.component';
import {config} from "../../consts";

const Section = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--grey, #848b9a);
    letter-spacing: -0.32px;
    padding: 30px 10%;
    margin-top: 80px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 1441px) {
        padding: 30px 3% 0 3%;
    }

    @media (max-width: 1280px) {
        padding: 20px 8% 0 8%;
    }

    @media (max-width: 991px) {
        flex-direction: column;
        padding: 20px;
    }
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    justify-content: start;
    width: 100%;
    margin: 0;
`;

const SectionTitle = styled.h2`
    color: var(--dark-blue, #000e2b);
    letter-spacing: -3.2px;
    font: 600 64px/1.2 Inter, sans-serif;
    margin-top: 25px;
    margin-bottom: 0;

    @media (max-width: 991px) {
        font-size: 40px;
    }
`;

const StyledDivider = styled.hr`
    background-color: rgba(132, 139, 154, 0.4);
    width: 100%;
    height: 1px;
    border: 0;
    margin: 0;
`;

const ProjectCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ProjectCardComponent = forwardRef((props, ref) => {
    let width = window.innerWidth;
    
    return (
        <Section ref={ref}>
            <JobBadge jobType={"Full-time jobs"} />
            <SectionTitleWrapper>
                <SectionTitle>
                    Working Experience
                </SectionTitle>
            </SectionTitleWrapper>
            
            <ProjectCardWrapper>
                { width > 1280 ? (
                    <>
                        <ProjectCard
                            className="project-card-1"
                            title="NFD.gg"
                            description="Сontributing to the development of desktop software for automated airdrop hunting across multiple blockchains"
                            date="01.2025 - Present"
                            buttonText="Go to website"
                            url={config.urls.nfd}
                        />
                        <StyledDivider />
                        <ProjectCard
                            className="project-card-1"
                            title="AirDrop Hunter"
                            description="Lead EVM developer, created solutions for multiple blockchains to automate airdrop acquisition from projects"
                            date="05.2023 - 01.2025"
                            buttonText="Go to website"
                            url={config.urls.hunter}
                        />
                        <StyledDivider />
                        <ProjectCard
                            className="project-card-2"
                            title="Crypto Hamster"
                            description="Developing an application to automate transactions in different blockchains"
                            date="03.2023 - 10.2023"
                            buttonText="Go to website"
                            url={config.urls.hamster}
                        />
                    </>
                ) :
                    (
                        <>
                            <ProjectCardMobile
                                className="project-card-1"
                                title="NFD.gg"
                                description="Сontributing to the development of desktop software for automated airdrop hunting across multiple blockchains"
                                date="01.2025 - Present"
                                buttonText="Go to website"
                                url={config.urls.nfd}
                            />
                            <StyledDivider />
                            <ProjectCardMobile
                                className="project-card-1"
                                title="AirDrop Hunter"
                                description="Lead EVM developer, created solutions for multiple blockchains to automate airdrop acquisition from projects"
                                date="05.2023 - 01.2025"
                                buttonText="Go to website"
                                url={config.urls.hunter}
                            />
                            <StyledDivider />
                            <ProjectCardMobile
                                className="project-card-2"
                                title="Crypto Hamster"
                                description="Developing an application to automate transactions in different blockchains"
                                date="03.2023 - 10.2023"
                                buttonText="Go to website"
                                url={config.urls.hamster}
                            />
                        </>
                    )
                }
            </ProjectCardWrapper>
        </Section>
    );
});

export default ProjectCardComponent;
