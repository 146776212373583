import React from "react";
import styled from "styled-components";

const ProjectDetailsComponent = ({ description }) => {
    return <Description>{description}</Description>;
};

const Description = styled.p`
    color: var(--grey, #848b9a);
    font-size: 16px;
    align-self: center;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-top: 19px;
    width: 308px;
    margin-left: auto;


    @media (max-width: 1210px) {
        margin-left: 0;
    }

    @media (max-width: 649px) {
        width: 100%;
    }
`;

export default ProjectDetailsComponent;