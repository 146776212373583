import React from "react";
import styled from "styled-components";
import ProjectDetailsComponent from './ProjectDetails.component';
import ProjectDateComponent from './ProjectDate.component';
import ProjectActionButton from './ProjectAction.component';

const ProjectCard = ({title, description, date, buttonText, url}) => {
    return (
        <Card>
            <ProjectTitle>{title}</ProjectTitle>
            <ProjectDetailsComponent description={description} />
            <Footer>
                <ProjectDateComponent date={date} />
                <ProjectActionButton text={buttonText} url={url} />
            </Footer>
        </Card>
    );
};

const Card = styled.article`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Название | Описание | Дата + Кнопка */
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-top: 10px;
    font-family: Inter, sans-serif;

    @media (max-width: 1650px) {
        grid-template-columns: 0.8fr 1fr 1fr;
    }

    @media (max-width: 1510px) {
        grid-template-columns: 0.5fr 1fr 1fr;
    }
    
    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        text-align: center;
    }
`;

const ProjectTitle = styled.h2`
  color: var(--dark-blue, #000e2b);
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
    align-self: center;
    flex-grow: 0.5;
  letter-spacing: -1.2px;
`;

const Footer = styled.footer`
    align-self: stretch;
    display: flex;
    gap: 43px;
    margin-left: auto;
`;

export default ProjectCard;